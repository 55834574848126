import logo from './MomentsLogo.svg';
import './App.css';

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <p>Our online shop is under construction.</p>
        <p>
          Please visit us in store at{' '}
          <a
            className='Moments-La-Vie-Instagram-Link'
            href='https://www.google.com/maps?ll=-34.42546,150.893848&z=15&t=m&hl=en&gl=AU&mapclient=embed&cid=16444593906708967237'
            target='_blank'
            rel='noopener noreferrer'
          >
            1/33 Balgownie Road, Fairy Meadow NSW 2519
          </a>
        </p>
        <a
          className=''
          href='tel:0499426199'
          target='_blank'
          rel='noopener noreferrer'
        >
          0499 426 199
        </a>
        <a
          className=''
          href='mailto:info@momentslavie.com.au'
          target='_blank'
          rel='noopener noreferrer'
        >
          info@momentslavie.com.au
        </a>
        <br />
        <a
          className=''
          href='https://www.instagram.com/momentslavie_/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src='https://img.icons8.com/fluency/512/instagram-new.png'
            alt='Instagram'
            height='60px'
          />
        </a>
        <br />
        <div id='map'>
          {' '}
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3292.501206545396!2d150.88463537682506!3d-34.38860447303196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b1319bbf9757cdd%3A0xe436ef07ecf0ef45!2sMoments%20La%20Vie!5e0!3m2!1sen!2sau!4v1698713758102!5m2!1sen!2sau'
            style={{ border: 0, width: '100%', height: '278px' }}
            allowfullscreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          ></iframe>
        </div>
      </header>
    </div>
  );
}

export default App;
